import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import haversine from "haversine";

import { SEO, ShowroomsFinder } from "../../../../components";
import ShowroomsContext from "../../../../contexts/Showrooms/Showrooms.context";
import ShowroomsGrid from "../ShowroomsGrid/ShowroomsGrid";
import ShowroomsGridSkeleton from "../ShowroomsGrid/ShowroomsGridSkeleton";
import NoResults from "../NoResults/NoResults";
import { useUserContext } from "../../../../contexts/User/User.context";
import { scrollToTop } from "../../../../utils/helpers";

import * as styles from "./ShowroomsResults.module.scss";
import useLocalization from "../../../../hooks/useLocalization";

import * as Analytics from "../../../../utils/analytics";

function parseAddress(addressString) {
  const addressElements = addressString.split(", ");

  let place, street, city, state, country;

  if (addressElements.length === 5) {
    [place, street, city, state, country] = addressElements;
  } else if (addressElements.length === 4) {
    [place, city, state, country] = addressElements;
  } else if (addressElements.length === 3) {
    [city, state, country] = addressElements;
  } else if (addressElements.length === 2) {
    [state, country] = addressElements;
  }

  return {
    place: place || "",
    street: street || "",
    city: city || "",
    state: state || "",
    country: country || "",
  };
}

const ShowroomsResults = () => {
  const {
    loadShowrooms,
    state: { showrooms, userLocation },
  } = useContext(ShowroomsContext);

  const {
    state: { loading: userLoading },
  } = useUserContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userLoading) {
      setLoading(true);
      loadShowrooms().then(res => {
        const { name } = userLocation;
        const addressFields = parseAddress(name);

        Analytics.track("Showroom geo search", {
          showroomsFound: res.length,
          fullAddress: name,
          ...addressFields,
        });
        setLoading(false);
        scrollToTop();
      });
    }
  }, [userLocation, userLoading]);

  const { regionalCopy } = useLocalization();

  const orderByDistance = showrooms => {
    const { latitude, longitude } = userLocation;

    return showrooms
      .map(showroom => {
        const distance = Math.round(
          haversine(
            {
              latitude: latitude,
              longitude: longitude,
            },
            {
              latitude: showroom.location.latitude,
              longitude: showroom.location.longitude,
            },
            { unit: regionalCopy.distanceUnit }
          )
        );

        return { ...showroom, distance: distance };
      })
      .sort((a, b) => {
        return a.distance - b.distance;
      })
      .sort((a, b) => {
        return b.status === "comingSoon" ? -1 : 1;
      });
  };

  return (
    <>
      <SEO title="Visit a Showroom" />
      <div className={cx(styles.ShowroomsResultsPage, "px-4  py-32 md:px-10")}>
        <div className={styles.pageHeader}>
          <h1 className="h1-styles mb-5">Visit a Showroom</h1>
          <p className="p-styles mb-10 text-secondary-color md:mb-12">
            {showrooms.items.length === 0
              ? "Find a showroom to experience Outer products in-person or connect virtually— from anywhere."
              : "Experience Outer products in person or connect virtually – from anywhere."}
          </p>
        </div>
        <div className={styles.finderContainer}>
          <ShowroomsFinder
            location="Results Page"
            initialValue={userLocation.name}
          />
        </div>
        <div className={styles.showroomsContainer}>
          {loading ? (
            <ShowroomsGridSkeleton />
          ) : showrooms.items.length === 0 ? (
            <NoResults />
          ) : (
            <ShowroomsGrid items={orderByDistance(showrooms.items)} />
          )}
        </div>
      </div>
    </>
  );
};

export default ShowroomsResults;
